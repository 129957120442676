import React from 'react';
import { FormHeaderContainer, FormHeaderMainTitle, FormHeaderSubTitle } from './elements';

const FormHeader = ({ mainTitle, subTitle }) => (
  <FormHeaderContainer>
    <div>
      <FormHeaderMainTitle>{mainTitle}</FormHeaderMainTitle>
      <FormHeaderSubTitle>{subTitle ? subTitle : null}</FormHeaderSubTitle>
    </div>
  </FormHeaderContainer>
);

export default FormHeader;
