import React, { useEffect, useState } from 'react';
import Cleave from 'cleave.js/react';
import colors from 'src/style/colors';
import fonts from 'src/style/fonts';
import { mediaQueries } from 'src/style/breakpoints';
import { css } from '@emotion/core';
import format from 'date-fns/format';

const DateInput = ({ onChange, value, errors, offerForm }) => {
  const [dateValue, setDateValue] = useState(value);

  useEffect(() => {
    if (value instanceof Date) {
      setDateValue(format(value || '', 'DD/MM/YYYY'));
    }
  }, [value]);

  const getDateFromRawValue = (rawValue) => {
    if (rawValue.length !== 8) return null;
    const day = rawValue.substr(0, 2);
    const month = rawValue.substr(2, 2);
    const year = rawValue.substr(4);
    return `${day}-${month}-${year}`;
  };

  const onDateChange = (event) => {
    const date = getDateFromRawValue(event.target.rawValue);
    onChange(date);
    setDateValue(event.target.value);
  };

  const inputStyleApply = css({
    ...fonts.HKCompakt.medium,
    backgroundColor: colors.white,
    borderRadius: 5,
    height: 42,
    paddingLeft: 20,
    outline: 'none',
    width: '100%',
    color: colors.purpleDark,
    border: errors ? `1px solid red` : `1px solid ${colors.midGrey}`,
    [mediaQueries.phoneOnly]: {
      width: '100%',
      maxWidth: 455,
    },
    [mediaQueries.tabletPortraitUp]: {
      width: '100%',
      maxWidth: 465,
    },
    [mediaQueries.tabletLandscapeUp]: {
      maxWidth: 585,
    },
    ':focus': {
      border: `1px solid ${colors.purple}`,
    },
  });
  const inputStyleOffer = css({
    ...fonts.HKCompakt.medium,
    backgroundColor: colors.white,
    borderRadius: 5,
    height: 42,
    paddingLeft: 20,
    outline: 'none',
    width: '100%',
    color: colors.purpleDark,
    border: errors ? `1px solid red` : `1px solid ${colors.midGrey}`,
    [mediaQueries.phoneOnly]: {
      width: '100%',
    },
    [mediaQueries.tabletPortraitUp]: {
      width: '100%',
      minWidth: 220,
    },
    [mediaQueries.tabletLandscapeUp]: {
      minWidth: 260,
    },
    ':focus': {
      border: `1px solid ${colors.purple}`,
    },
  });
  return (
    <Cleave
      data-cy="dob"
      placeholder="JJ/MM/AAAA"
      options={{ date: true, time: false, datePattern: ['d', 'm', 'Y'] }}
      onChange={onDateChange}
      css={offerForm ? inputStyleOffer : inputStyleApply}
      value={dateValue}
    />
  );
};

export default DateInput;
