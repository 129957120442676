import React from 'react';
import fonts from 'src/style/fonts';
import colors from 'src/style/colors';
import { useMediaQueries } from '../../utils/MediaQueries';

const BackButton = ({ onClick }) => {
  const mqs = useMediaQueries();

  return (
    <button
      data-cy="backButton"
      type="button"
      onClick={onClick}
      css={{
        ...fonts.HKCompakt.bold,
        color: colors.purple,
        fontSize: 19,
        cursor: 'pointer',
        padding: '10px 15px 10px 0px',
      }}
    >
      {mqs.tabletPortraitUp ? (
        <svg css={{ marginRight: 5 }} width="15" height="10" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.5 4.5h9M1.5 4.5l3-3M4.5 7.5l-3-3"
            stroke="#5C48D3"
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
          />
        </svg>
      ) : (
        <svg
          css={{
            marginRight: 8,
          }}
          width="20"
          height="15"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g strokeWidth="2" stroke="#5C48D3" fill="none" fillRule="evenodd" strokeLinecap="round">
            <path d="M1.9 7.5h16.2M1.9 7.5l5.4-5.57142857M7.3 13.0714286L1.9 7.5" />
          </g>
        </svg>
      )}
      {mqs.tabletPortraitUp ? 'Précédent' : null}
    </button>
  );
};

export default BackButton;
