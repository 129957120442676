import React from "react";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const GRCComponent = ({ action, setGRCToken }) => {
    //  reCAPTCHA tokens expire after two minutes and can't be used more than once
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [recaptchaBadge, setRecaptchaBadge] = React.useState(false);

    React.useEffect(() => {
        // we make sure that the recaptcha is loaded on the page (checked every 200ms during 10s)
        const timer = (delay, limit) => {
            const start = Date.now()
            const interval = setInterval(function () {
                const currentTime = Date.now() - start
                if (document.getElementsByClassName('grecaptcha-badge').length > 0) {
                    document.getElementsByClassName('grecaptcha-badge')[0].style.zIndex = '999999'
                    setRecaptchaBadge(true);
                    clearInterval(interval);
                }
                if (currentTime > limit) {
                    setRecaptchaBadge(false);
                    clearInterval(interval);
                }
            }, delay);
        }
        timer(200, 10000);
    }, [])

    React.useEffect(() => {
        let interval;
        const fetchToken = async () => {
            if (!executeRecaptcha) {
                return;
            }
            try {
                if (recaptchaBadge) {
                    // if the recaptcha is loaded, we generate a token
                    const token = await executeRecaptcha(action);
                    setGRCToken(token)
                    interval = setInterval(
                        async function () {
                            const token = await executeRecaptcha(action);
                            setGRCToken(token)
                        }
                        , 110000);
                }
            } catch (error) {
                console.error(error)
            }
        };
        fetchToken();
        return () => {
            clearInterval(interval)
        }
    }, [executeRecaptcha, action, setGRCToken, recaptchaBadge])

    return null
}

export default GRCComponent;
