import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GRCComponent } from '../Form';
import '../Layout/layout.css';

const GRC = ({ children, action, setGRCToken }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcTtKcUAAAAAJqU8NR668KwUhpITRo01udzWvo9">
      <GRCComponent action={action} setGRCToken={setGRCToken} />
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default GRC;
