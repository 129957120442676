import React from 'react';
import { MultiSelectSmallContainer } from './elements';

const MultiSelectSmall = ({ className, isLeft, children, isSelected = false, ...props }) => (
  <MultiSelectSmallContainer
    isLeft={isLeft}
    className={className}
    isSelected={isSelected}
    {...props}
  >
    {children}
  </MultiSelectSmallContainer>
);

export default MultiSelectSmall;
